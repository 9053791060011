// Footer.js
import React from 'react';
import './style/footer.css';
import IubendaLink from './IubendaLink';
import CookieConsent from './CoockieConsent';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>&copy; {new Date().getFullYear()} Lorenzo Mameli. All rights reserved.</p>
                <IubendaLink 
                    url="https://www.iubenda.com/privacy-policy/91935924" 
                    title="Privacy Policy" 
                />

            </div>
        </footer>
    );
};

export default Footer;
