import { useEffect } from "react";

const CookieConsent = () => {
  useEffect(() => {
    // Configurazione Iubenda
    const scriptConfig = document.createElement("script");
    scriptConfig.innerHTML = `
      var _iub = _iub || [];
      _iub.csConfiguration = {
        "siteId": 3833481,
        "cookiePolicyId": 91935924,
        "lang": "it",
        "storage": { "useSiteId": true }
      };
    `;
    document.head.appendChild(scriptConfig);

    // Script autoblocking
    const scriptBlocking = document.createElement("script");
    scriptBlocking.src = "https://cs.iubenda.com/autoblocking/3833481.js";
    scriptBlocking.async = true;
    document.head.appendChild(scriptBlocking);

    // Script principale Iubenda
    const scriptMain = document.createElement("script");
    scriptMain.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
    scriptMain.async = true;
    scriptMain.charset = "UTF-8";
    document.head.appendChild(scriptMain);

    return () => {
      document.head.removeChild(scriptConfig);
      document.head.removeChild(scriptBlocking);
      document.head.removeChild(scriptMain);
    };
  }, []);

  return null; // Non renderizza nulla
};

export default CookieConsent;
