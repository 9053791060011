import { useEffect } from "react";

const IubendaLink = ({ url, title }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <a
      href={url}
      className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe"
      title={title}
    >
      {title}
    </a>
  );
};

export default IubendaLink;
